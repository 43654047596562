import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Alert, Button, Dropdown, ButtonGroup, Breadcrumb, InputGroup, Modal, FormCheck } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link, useHistory } from 'react-router-dom';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { MemberReportTable } from "../components/MemberReportTable";
import Preloader from "../components/Preloader";
import Loader from "../components/Loader";


export default () => {

	const [search, setSearch] = useState("");

	const [data, setData] = useState([]);

	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [pageLoad, setPageLoad] = useState(true);


	const storedUser = localStorage.getItem('user');

	if (!storedUser) {
		history.push('/'); // Replace '/login' with the actual URL of your login page
		return false;
	}
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const role_idd = userObject.role_id;
	const user_idd = userObject.user_id;
	let setup_admin_permission = userObject.setup_admin_permission;
	let invite_user_permission = userObject.invite_user_permission;
	let suspend_user_permission = userObject.suspend_user_permission;
	var delete_user_permission = userObject.delete_user_permission;


	if (loading && pageLoad) {

		// return <div>Loading...</div>;

	}

	return (

		<>
			{loading && <Preloader show={true} />}
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
						<Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
						<Breadcrumb.Item>Home</Breadcrumb.Item>
						<Breadcrumb.Item active>{role_idd !== 1 ? 'Invited Members Report' : 'Members Report'}</Breadcrumb.Item>
					</Breadcrumb>
					<h4>{role_idd !== 1 ? 'Invited Members Report' : 'Members Report'}</h4>
				</div>
				<div className="btn-toolbar mb-2 mb-md-0">
				</div>
			</div>

			<MemberReportTable data={search} />

		</>

	);

};

