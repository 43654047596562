import React, { useState, useEffect, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal, Row, Col, Form } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



import { Routes } from "../routes";

import transactions from "../data/transactions";

import axios from 'axios';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Swal from 'sweetalert2';



const ValueChange = ({ value, suffix }) => {

	const valueIcon = value < 0 ? faAngleDown : faAngleUp;

	const valueTxtColor = value < 0 ? "text-danger" : "text-success";



	return (

		value ? <span className={valueTxtColor}>

			<FontAwesomeIcon icon={valueIcon} />

			<span className="fw-bold ms-1">

				{Math.abs(value)}{suffix}

			</span>

		</span> : "--"

	);

};







export const NotificationTable = (searchKey) => {
	

	const [data, setData] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);

	const [startPage, setStartPage] = useState(1);

	const [endPage, setEndPage] = useState(1);

	const [totalRecord, setTotalRecord] = useState(1);

	const [perPage, setPerPage] = useState(1);

	const [totalPages, setTotalPages] = useState(1);

	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);

	const [filterDate, setFilterDate] = useState("");

	const [type, setType] = useState("");
	
	const [currentSort, setCurrentSort] = useState("notifications.id");
	
	const [currentDirection, setCurrentDirection] = useState("desc");



	const [showCommitmentDefault, setShowCommitmentDefault] = useState(false);

	const [commitmentData, setCommitmentData] = useState([]);

	const handleCloseCommitment = () => setShowCommitmentDefault(false);



	const handleDateChange = (event) => {

		setFilterDate(event.target.value);

	};



	const handleTypeChange = (event) => {

		setType(event.target.value);

	};





	const [showDefault, setShowDefault] = useState(false);

	const handleClose = () => setShowDefault(false);
	
	
	
	
	
	const [formDataEdit, setFormDataEdit] = useState({
		description: '',
		notification_id: '',
		created_at: '',
		title: ''
	});
	
	
	const [errorsEdit, setErrorsEdit] = useState({
		description: '',
		created_at: '',
		title: ''
	});
	



	{/*Sorting*/ }

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });





	const handleSort = (key) => {

		let direction = 'asc';

		if (sortConfig.key === key && sortConfig.direction === 'asc') {

			direction = 'desc';

		}

		setSortConfig({ key, direction });

		setCurrentSort(key);
		
		setCurrentDirection(direction);

		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const user_id = userObject.user_id;



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/notificationList/' + user_id;



		const formData = new FormData();

		formData.append('page', 1);

		formData.append('search', search);

		formData.append('filterDate', filterDate);

		formData.append('type', type);

		formData.append('sortBy', key);

		formData.append('orderBy', direction);



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorizations: accessToken // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};





	const getSortIcon = (columnKey) => {

		if (sortConfig.key === columnKey) {

			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;

		}

		return <FontAwesomeIcon icon={faSort} />;

	};

	



	{/*End Sorting*/ }





	const fetchData = async page => {



		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const user_id = userObject.user_id;



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/notificationList/' + user_id;



		const formData = new FormData();

		formData.append('page', page);

		formData.append('search', search);

		formData.append('filterDate', filterDate);

		formData.append('type', type);
		
		formData.append('sortBy', currentSort);
		
		formData.append('orderBy', currentDirection);



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorizations: accessToken // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};






	useEffect(() => {

		setSearch(searchKey?.data);

	}, [searchKey?.data]);

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);





	const handlePageChange = page => {

		setCurrentPage(page);

	};



	const onPageChange = (pageNo) => {

		setCurrentPage(pageNo);

		fetchData(pageNo);

	};



	const onViewData = (savers_name, title, description, formattedDate, formattedTime, collection_month_request_id) => {



		let datas = { "savers_name": savers_name, "title": title, "description": description, "formattedDate": formattedDate, "formattedTime": formattedTime, "collection_month_request_id": collection_month_request_id }

		setCommitmentData(datas);



		setShowCommitmentDefault(true);

	};
	
	
	
	
	
	
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		let formattedValue = value;
		if (name === "created_at" && value instanceof Date) {
			formattedValue = value.toLocaleString('en-CA', { // en-CA for YYYY-MM-DD format
			  year: 'numeric',
			  month: '2-digit',
			  day: '2-digit',
			  hour: '2-digit',
			  minute: '2-digit',
			  second: '2-digit',
			  hour12: false,
			}).replace(/,/, '');
		 }
		 
		setFormDataEdit({
			...formDataEdit,
			[name]: formattedValue,
		});
	};
	
	
	const updateStatus = (notification_id) => {
		
		const storedUser = localStorage.getItem('user');
		const userObject = JSON.parse(storedUser);
		const accessToken = userObject.access_token;
		const user_id = userObject.user_id;

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/notifications/status';


		const formData = new FormData();
		formData.append('notification_id', notification_id);
		setLoading(true);


		axios.post(apiBaseURL, formData, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}).then(({ data }) => {
				if (data.status === "success") {
					fetchData(currentPage);
					
					if(data.data.is_show == "0"){
						Swal.fire("Notification disabled successfully.", '', 'success').then(() => {
							window.location.reload();
						})
					} else {
						Swal.fire("Notification enabled successfully.", '', 'success').then(() => {
							window.location.reload();
						})
					}
					setLoading(false);
				}
			}).catch(error => {
				setLoading(false);
			});	
	};



	const onEditData = (notification_id, description, created_at, title) => {

		const storedUser = localStorage.getItem('user');
		const userObject = JSON.parse(storedUser);
		const accessToken = userObject.access_token;
		const user_id = userObject.user_id;

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/notifications/edit';


		const formData = new FormData();
		formData.append('notification_id', notification_id);
		setLoading(true);
		
		const initialDate = new Date(created_at);


		axios.post(apiBaseURL, formData, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}).then(({ data }) => {
				if (data.status === "success") {
					
					const formattedValue = initialDate.toLocaleString('en-CA', { // en-CA for YYYY-MM-DD format
					  year: 'numeric',
					  month: '2-digit',
					  day: '2-digit',
					  hour: '2-digit',
					  minute: '2-digit',
					  second: '2-digit',
					  hour12: false,
					}).replace(/,/, '');
					
					setFormDataEdit({
						...formData,

						description: description,
						notification_id: notification_id,
						created_at:formattedValue,
						title:title

					});
					
					setShowDefault(true);
				}
				setLoading(false);
			}).catch(error => {
				setLoading(false);
			});	
	};



	const updateNotification = (e) => {
		
		e.preventDefault();

		// Validate
		let valid = true;

		const newErrors = {
			description: '',
		};

		if (formDataEdit.description === "") {
			newErrors.description = 'Description Should not be empty';
			valid = false;
		}
		if (formDataEdit.title === "") {
			newErrors.title = 'Title Should not be empty';
			valid = false;
		}
		setErrorsEdit(newErrors);
		
		if(valid) {
			
			const storedUser = localStorage.getItem('user');
			const userObject = JSON.parse(storedUser);
			const accessToken = userObject.access_token;
			const user_id = userObject.user_id;
			
			setLoading(true);
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/notifications/update';


			axios.post(apiBaseURL, formDataEdit, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}).then(({ data }) => {

					if (data.status === "success") {
						setShowDefault(false);
						setLoading(false);
					}
					setLoading(false);

					fetchData(currentPage);

				}).catch(error => {
					setLoading(false);
				});
			
		}
		
	};




	const handleFilter = (event) => {

		fetchData(currentPage);

	}

	const handleReset = (event) => {

		setFilterDate("");

		setType("");

		fetchData(currentPage);

		fetchData(currentPage);

	}









	if (loading) {

		//return <div>Loading...</div>;

	}



	const items = [];

	for (let i = 0; i < totalPages; i++) {

		items.push(i + 1);

	}





	const totalUsers = data.length;



	const srStart = (currentPage - 1) * perPage;



	const modifiedData = data.map((t, index) => ({

		...t,

		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1

	}));



	const prevPage = currentPage > 1 ? currentPage - 1 : 1;

	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;





	const TableRow = (props) => {

		const { id, title, description, collection_month_request_id, collection_month, serialNumber, savers_name, created_at, is_show, to_user } = props;





		// Convert created_at to a Date object

		const createDate = new Date(created_at);



		// Format date

		const formattedDate = createDate.toLocaleDateString(undefined, {

			day: 'numeric',

			month: 'long',

			year: 'numeric',

		});



		// Format time

		const formattedTime = createDate.toLocaleTimeString(undefined, {

			hour: 'numeric',

			minute: 'numeric',

			hour12: true,

		});
		function truncateString(str, maxLength) {
			if (str.length > maxLength) {
			  return str.substr(0, maxLength) + "...";
			}
			return str;
		  }
		  


		const dateObject = new Date(created_at);
		const formattedDates = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}`;



		return (

			<tr>

				<td>

					<span className="fw-normal">

						{serialNumber}

					</span>

				</td>

				<td>

					<span className="fw-normal">
					<a href={`users?user=${to_user}`}>
						{savers_name}
					</a>
					</span>

				</td>

				<td>

					<span className="fw-normal">

						{truncateString(title,40)}

					</span>

				</td>

				<td>

					<span className="fw-normal">

						{truncateString(description,40)}

					</span>

				</td>

				<td>

					<span className="fw-normal">

						{formattedDate} {formattedTime}

					</span>

				</td>
				
				<td>
					<label className="switch">
						<input 
							type="checkbox" 
							name="email_switch" 
							checked={is_show == "1"} // Check if is_show is 1
							onChange={() => updateStatus(id)}
						/>
						<span className="slider round"></span>
					</label>
				</td>

				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item className="text-primary" onClick={() => onViewData(savers_name, title, description, formattedDate, formattedTime, collection_month_request_id)}>
								<FontAwesomeIcon icon={faEye} className="me-2"  /> View
							</Dropdown.Item>
							<Dropdown.Item className="text-primary" onClick={() => onEditData(id, description, created_at, title)}>
								<FontAwesomeIcon icon={faEdit} className="me-2" />Edit
							</Dropdown.Item>
						</Dropdown.Menu>
						
					</Dropdown>
				</td>

			</tr>

		);

	};



	return (

		<>

			{loading && <Preloader show={true} />}




			<React.Fragment>
				<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>

					<Modal.Header>
						<Modal.Title className="h6">Edit Notification</Modal.Title>
						<Button variant="close" aria-label="Close" onClick={handleClose} />
					</Modal.Header>
					
					<Modal.Body>
						<Row>
							<Col xs={12} xl={12}>
								<Card border="light" className="bg-white shadow-sm mb-4">
									<Card.Body>
										<h5 className="mb-4">Edit Notification</h5>
										<Form onSubmit={updateNotification}>
											<Row>
											
												<Col md={12} className="mb-3">
													<Form.Group id="notification_title">
														<Form.Label>Title</Form.Label>
														<Form.Control 
															name="title" 
															placeholder="Title" 
															rows={4} // Optional: specify the number of rows for the textarea
															value={formDataEdit.title}
															onChange={handleInputChange}
														/>
													</Form.Group>
													<div className="error-message">{errorsEdit.title}</div>
												</Col>
											
												<Col md={12} className="mb-3">
													<Form.Group id="edit_notification">
														<Form.Label>Description</Form.Label>
														<Form.Control 
															as="textarea" // Change this to "textarea"
															name="description" 
															placeholder="Notification" 
															rows={4} // Optional: specify the number of rows for the textarea
															value={formDataEdit.description}
															onChange={handleInputChange}
														/>
													</Form.Group>
													<div className="error-message">{errorsEdit.description}</div>
												</Col>
												
												<Col md={12} className="mb-3">
													<Form.Group id="created_at">
													  <Form.Label>Created At</Form.Label>
													  <Datetime
														name="created_at" 
														value={formDataEdit.created_at}
														onChange={(date) => handleInputChange({ target: { name: 'created_at', value: date.toDate() } })}
														dateFormat="YYYY-MM-DD"
														timeFormat="HH:mm:ss"
														inputProps={{ 
															placeholder: "Select Date and Time", 
															className: "form-control", 
															readOnly: true 
														}}
														isValidDate={(current) => current.isBefore(Datetime.moment()) || current.isSame(Datetime.moment(), 'day')}
													  />
													</Form.Group>
													<div className="error-message">{errorsEdit.created_at}</div>
												</Col>
												
											</Row>

											<div className="mt-3 center">
												<Button variant="primary" type="submit">Update</Button>
											</div>
										</Form>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Modal.Body>
				</Modal>
			</React.Fragment>



			<div className="row">

				<div className="col-md-4">

					Date: <input type="date" name="date" id="date" className="form-control" value={filterDate} onChange={handleDateChange} />

				</div>

				<div className="col-md-4">

					Notification Type: <select name="type" id="type" className="form-control" value={type} onChange={handleTypeChange}>

						<option value="">Choose...</option>

						<option value="requested">Requested</option>

						<option value="proposal">Proposal</option>

						<option value="final_details">Final Detail</option>

						<option value="complete">Completed</option>

					</select>

				</div>

				<div className="col-md-4">

					<p style={{ marginBottom: '23px' }}></p>

					<Button variant="primary" type="button" onClick={handleFilter}>Filter</Button> &nbsp;

					{/* <Button variant="primary" type="button" onClick={handleReset}>Reset</Button> */}

				</div>

			</div><br />



			<Card border="light" className="table-wrapper table-responsive shadow-sm">

				<Card.Body className="pt-0">

					<Table hover className="user-table align-items-center">

						<thead>

							<tr>

								<th className="border-bottom notsort" onClick={() => handleSort('notifications.id')}>Record Id {getSortIcon('notifications.id')}</th>
								<th className="border-bottom" onClick={() => handleSort('users.name')}>Member Name {getSortIcon('users.name')}</th>

								<th className="border-bottom" onClick={() => handleSort('notifications.title')}>Title {getSortIcon('notifications.title')}</th>

								<th className="border-bottom" onClick={() => handleSort('notifications.description')}>Description {getSortIcon('notifications.description')}</th>

								<th className="border-bottom" onClick={() => handleSort('notifications.created_at')}>Created Date {getSortIcon('notifications.created_at')}</th>
								
								<th className="border-bottom">Status</th>
								
								<th className="border-bottom">Action</th>

							</tr>

						</thead>

						<tbody>

							{modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)}

						</tbody>

					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">

						<Nav>

							<Pagination className="mb-2 mb-lg-0">

								<Pagination.Prev onClick={() => onPageChange(prevPage)}>

									Previous

								</Pagination.Prev>



								{items.map((item, index) => (
									((item <  currentPage && (item - currentPage) > -5) || (item >  currentPage && (item - currentPage) < 6) || item === currentPage) ? 

									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item> : null

								))}



								<Pagination.Next onClick={() => onPageChange(nextPage)}>

									Next

								</Pagination.Next>

							</Pagination>

						</Nav>

						<small className="fw-bold">

							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries

						</small>

					</Card.Footer>

				</Card.Body>

			</Card>





			{/* View For Saving Commitments */}

			<React.Fragment>

				<Modal as={Modal.Dialog} centered show={showCommitmentDefault} onHide={handleCloseCommitment}>

					<Modal.Header>

						<Modal.Title className="h6">Notification Details</Modal.Title>

						<Button variant="close" aria-label="Close" onClick={handleCloseCommitment} />

					</Modal.Header>



					<Modal.Body>

						<Row>

							<Col md={12}>

								<Col md={9} style={{ float: 'right' }}>

									<p>{commitmentData.savers_name ?? ""}</p>

								</Col>

								<Col md={3}>

									<b>Member :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={9} style={{ float: 'right' }}>

									<p>{commitmentData.title ?? ""}</p>

								</Col>

								<Col md={3}>

									<b>Title :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={9} style={{ float: 'right' }}>

									<p>{commitmentData.description ?? ""}</p>

								</Col>

								<Col md={3}>

									<b>Description :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={9} style={{ float: 'right' }}>

									<p>{commitmentData.formattedDate ?? ""} {commitmentData.formattedTime ?? ""}</p>

								</Col>

								<Col md={3}>

									<b>Time :</b>

								</Col>

							</Col>

						</Row>

					</Modal.Body>



				</Modal>

			</React.Fragment>

			{/*End View For Saving Commitments */}



		</>

	);

};