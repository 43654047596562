import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card, Form, Alert, Button, Dropdown, ButtonGroup, Breadcrumb, InputGroup, Modal, FormCheck } from '@themesberg/react-bootstrap';

import { Routes } from "../routes";

import { Link, useHistory } from 'react-router-dom';

import Datetime from "react-datetime";

import moment from "moment-timezone";

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';

import { WritingUserTable } from "../components/WritingUserTable";

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



export default () => {

	const [birthday, setBirthday] = useState("");

	const [showDefault, setShowDefault] = useState(false);
	const [showedit, setshowedit] = useState(false);
	const [editdata, seteditdata] = useState({});

	const handleClose = () => {
		setShowDefault(false);
		setshowedit(false);
	};

	const [search, setSearch] = useState("");

	const [data, setData] = useState([]);

	const history = useHistory();

	const [loading, setLoading] = useState(true);

	const [countries, setCountry] = useState([]);

	const [pageLoad, setPageLoad] = useState(true);

	const [mobileNumber, setmobileNumber] = useState("+44");


	const storedUser = localStorage.getItem('user');

	if (!storedUser) {
		history.push('/'); // Replace '/login' with the actual URL of your login page
		return false;
	}

	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const role_idd = userObject.role_id;
	const user_idd = userObject.user_id;

	let setup_admin_permission = userObject.setup_admin_permission;
	let invite_user_permission = userObject.invite_user_permission;
	let suspend_user_permission = userObject.suspend_user_permission;
	var delete_user_permission = userObject.delete_user_permission;


	const [error, setError] = useState(null);


	const handleInputChangeSearch = (e) => {
		const { name, value } = e.target;
		setSearch(value);

	};


	var del_per = "";

	useEffect(() => {
		setLoading(true);
		const apiBaseURL1 = process.env.REACT_APP_API_BASE_URL + '/country_list';

		axios.get(apiBaseURL1, {})
			.then(({ data }) => {

				if (data.status === "success") {

					setCountry(data.data);

					const storedUsersss = localStorage.getItem('user');

					console.log("storedUsersss", storedUsersss);

					//localStorage.setItem('user', JSON.stringify(data.content));

					let user_id = storedUsersss.user_id

					const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/' + user_idd;
					const formData = new FormData();

					setLoading(true);

					axios.post(apiBaseURL, formData, {
						headers: {
							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
						}
					})
						.then(({ data }) => {
							if (data.status === "success") {

								console.log("YData", data.data.data);

								let udATA = {
									'status_code': 200,
									'access_token': accessToken,
									'token_type': 'Bearer',
									'user_name': data.data.data[0].name,
									'user_email': data.data.data[0].email,
									'user_id': data.data.data[0].id,
									'is_saving_commitment': data.data.data[0].is_saving_commitment,
									'role_id': data.data.data[0].role_id,
									'setup_admin_permission': data.data.data[0].setup_admin_permission,
									'invite_user_permission': data.data.data[0].invite_user_permission,
									'assign_month_permission': data.data.data[0].assign_month_permission,
									'approval_request_permission': data.data.data[0].approval_request_permission,
									'suspend_user_permission': data.data.data[0].suspend_user_permission,
									'delete_user_permission': data.data.data[0].delete_user_permission,
								}

								localStorage.setItem('user', JSON.stringify(udATA));
							}
							setLoading(false);
							setPageLoad(false);
						}).catch(error => {
							setLoading(false);
							setPageLoad(false);
						});
				}

			}).catch(error => {
			});
	}, []);


	const btnWidth = {
		width: '125px'
	};

	return (

		<>

			{loading && <Preloader show={true} />}

			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">

				<div className="d-block mb-4 mb-md-0">

					<Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>

						<Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>

						<Breadcrumb.Item>Home</Breadcrumb.Item>

						<Breadcrumb.Item active>{role_idd !== 1 ? 'Waiting List' : 'Waiting List'}</Breadcrumb.Item>

					</Breadcrumb>

					<h4>{role_idd !== 1 ? 'Waiting List' : 'Waiting List'}</h4>

				</div>

				<div className="btn-toolbar mb-2 mb-md-0">

				</div>

			</div>



			<div className="table-settings mb-4">

				<Row className="justify-content-between align-items-center">

					<Col xs={8} md={6} lg={3} xl={4}>

						<InputGroup>

							<InputGroup.Text>

								<FontAwesomeIcon icon={faSearch} />

							</InputGroup.Text>

							<Form.Control type="text" placeholder="Search" name="searchKeyword" onChange={handleInputChangeSearch} />

						</InputGroup>

					</Col>

					<Col xs={4} md={2} xl={2} className="ps-md-0 text-end">

					</Col>

				</Row>

			</div>

			<WritingUserTable data={search} />

		</>

	);

};

