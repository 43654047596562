
import React, { useState, useEffect } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faSignOutAlt, faTimes, faUser,faCog, faBell, faEnvelope, faPiggyBank, faHandshake, faInbox, faStar } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/logo.png";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import Profile3 from "../assets/img/team/profilesImage.png";
import { useHistory } from 'react-router-dom';
import axios from 'axios';

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);
  const history = useHistory();
  const storedUser = localStorage.getItem('user');
 
  const userObject = JSON.parse(storedUser);
  const accessToken = userObject ? userObject.access_token : null;
  
	if (!accessToken) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
  
  const user_id = userObject.user_id;
  const is_saving_commitment = userObject.is_saving_commitment;
  const role_id = userObject.role_id;
  const approval_request_permission = userObject.approval_request_permission;
  const formattedMemberId = `M-${user_id.toString().padStart(6, '0')}`;
  

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text" style={{ fontSize: '15px' }}>{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text" style={{ fontSize: '15px' }}>{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none topBar">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={Profile3} className="card-img-top rounded-circle border-white" /> 
                </div>
				{/*
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Login.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
				*/}
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
			  

				<div class="sidebar-logo">
			      <NavItem title="Savvey Savers Networks" link="#" image={ReactHero} />
			    </div> 


			  <NavItem title="Dashboard" link={Routes.Dashboard.path} icon={faChartPie} />
			  {role_id === 1 && (
				<NavItem title="Manage Users" icon={faUser } link={Routes.Users.path} />
			  )}
			  {role_id === 1 && (
				<NavItem title="Savings Commitments" icon={faHandshake } link={Routes.SavingCommitment.path} />
			  )}
			  {role_id === 0 && (
				<NavItem title="Savings Commitments" icon={faHandshake } link={Routes.SavingCommitmentSavers.path} />
			  )}
			  {role_id === 0 && (
				<NavItem title="My Invitations" icon={faUser } link={Routes.Users.path} />
			  )}
			  {role_id === 1 && (
				<NavItem title="Notification" icon={faBell } link={Routes.Notification.path} />
			  )}
			  
			  {role_id === 1 && approval_request_permission === "1" && (
				<NavItem title="Collection Month Requests" icon={faInbox } link={Routes.CollectionMonthRequest.path} />
			  )}

			 {role_id!== 1 && (
				<NavItem title="Collection Month Requests" icon={faInbox } link={Routes.CollectionMonthRequest.path} />
			  )}
			  
			  {role_id === 1 && (
				<NavItem title="Deleted Users" icon={faTimes  } link={Routes.DeletedUsers.path} />
			  )}
			  
			  {role_id === 1 && (
				<NavItem title="Waiting List" icon={faUser } link={Routes.WritingUsers.path} />
			  )}
			  
			  {/*
			  <a href="https://g.co/kgs/yDegUC9" class="nav-link" target="_blank">
				<span>
					<span class="sidebar-icon">
						<FontAwesomeIcon icon={faStar} />
					</span>
					<span className="sidebar-text" style={{ fontSize: '15px' }}>Reviews</span>
			   </span>
			  </a> 
			  */}
			  
			  {role_id === 1 && (
				  <CollapsableNavItem eventKey="tables/" title="Setting" icon={faCog}>
					<NavItem title="Security Question" link={Routes.SecurityQuestions.path} />
					<NavItem title="Manage Commitment" link={Routes.CommitmentMonth.path} />
						{/*
					<NavItem title="Email Template" icon={faEnvelope  } link={Routes.EmailTemplate.path} />
						*/}
				  </CollapsableNavItem> 
				)}
				
				{role_id === 1 && (
				  <CollapsableNavItem eventKey="tables/" title="Report" icon={faChartPie}>
					<NavItem title="Member Report" link={Routes.MemberReport.path} />
					<NavItem title="Saving Commitment Report" link={Routes.SavingCommitmentReport.path} />
				  </CollapsableNavItem>
				)}
			  
        </Nav>
			
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
