import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Landing from "./Landing";
import Login from "./Login";
import ShowPage from "./ShowPage";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Register from "./Register";
import Registration from "./Registration";
import HelpMe from "./HelpMe";
import Dashboard from "./dashboard/DashboardOverview";
import Users from "./Users";
import AddUser from "./AddUser";
import Profile from "./Profile";
import Notification from "./Notification";
import CollectionMonthRequest from "./CollectionMonthRequest";
import ViewCollectionMonthRequest from "./ViewCollectionMonthRequest";
import SavingCommitmentSavers from "./SavingCommitmentSavers";

import SecurityQuestions from "./SecurityQuestions";
import EmailTemplate from "./EmailTemplate";


import SavingCommitment from "./SavingCommitment";


import DeletedUsers from "./DeletedUsers";
import WritingUsers from "./WritingUsers";
import MemberReport from "./MemberReport";
import SavingCommitmentReport from "./SavingCommitmentReport";


import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import NotFoundPage from "./examples/NotFound";

import CommitmentMonth from "./CommitmentMonth";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import LogoutTimer from './LogoutTimer';

//import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';






const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const TIMEOUT = 10 * 60 * 1000;	
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }



  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />
		<LogoutTimer timeout={TIMEOUT} />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Landing.path} component={Landing} />
    <RouteWithLoader exact path='/about-us' component={ShowPage} />
    <RouteWithLoader exact path='/faqs' component={ShowPage} />
    <RouteWithLoader exact path='/terms-and-conditions' component={ShowPage} />
    <RouteWithLoader exact path='/membership-agreement' component={ShowPage} />
    <RouteWithLoader exact path='/fee-schedule' component={ShowPage} />
	<RouteWithLoader exact path='/contact' component={ShowPage} />
    <RouteWithLoader exact path={Routes.Login.path} component={Login} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Register.path} component={Register} />
    <RouteWithLoader exact path={Routes.Registration.path} component={Registration} />
    <RouteWithLoader exact path={Routes.HelpMe.path} component={HelpMe} />

    <RouteWithSidebar exact path={Routes.Dashboard.path} component={Dashboard} />
    <RouteWithSidebar exact path={Routes.Users.path} component={Users} />
    <RouteWithSidebar exact path={Routes.AddUser.path} component={AddUser} />
    <RouteWithSidebar exact path={Routes.Profile.path} component={Profile} />
    <RouteWithSidebar exact path={Routes.CollectionMonthRequest.path} component={CollectionMonthRequest} />

    <RouteWithSidebar exact path={Routes.SavingCommitment.path} component={SavingCommitment} />
    <RouteWithSidebar exact path={Routes.SavingCommitmentSavers.path} component={SavingCommitmentSavers} />
    <RouteWithSidebar exact path={Routes.ViewCollectionMonthRequest.path} component={ViewCollectionMonthRequest} />

    <RouteWithSidebar exact path={Routes.SecurityQuestions.path} component={SecurityQuestions} />
    <RouteWithSidebar exact path={Routes.EmailTemplate.path} component={EmailTemplate} />

    <RouteWithSidebar exact path={Routes.Notification.path} component={Notification} />


    <RouteWithSidebar exact path={Routes.CommitmentMonth.path} component={CommitmentMonth} />
    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />

    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
	
	
	
	<RouteWithSidebar exact path={Routes.DeletedUsers.path} component={DeletedUsers} />
	<RouteWithSidebar exact path={Routes.WritingUsers.path} component={WritingUsers} />
	<RouteWithSidebar exact path={Routes.MemberReport.path} component={MemberReport} />
	<RouteWithSidebar exact path={Routes.SavingCommitmentReport.path} component={SavingCommitmentReport} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
