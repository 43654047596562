import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card, Form, Alert, Button, Dropdown, ButtonGroup, Breadcrumb, InputGroup, Modal, FormCheck } from '@themesberg/react-bootstrap';

import { Routes } from "../routes";

import { Link, useHistory } from 'react-router-dom';

import Datetime from "react-datetime";

import moment from "moment-timezone";

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';

import { DeletedUserTable } from "../components/DeletedUserTable";

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



export default () => {



	const [birthday, setBirthday] = useState("");

	const [showDefault, setShowDefault] = useState(false);
	const [showedit, setshowedit] = useState(false);
	const [editdata, seteditdata] = useState({});

	const handleClose = () => {
		setShowDefault(false);
		setshowedit(false);
	};

	const [search, setSearch] = useState("");

	const [data, setData] = useState([]);

	const history = useHistory();

	const [loading, setLoading] = useState(true);

	const [countries, setCountry] = useState([]);

	const [pageLoad, setPageLoad] = useState(true);

	const [mobileNumber, setmobileNumber] = useState("+44");



	const storedUser = localStorage.getItem('user');

	if (!storedUser) {

		// Redirect to the login URL

		history.push('/'); // Replace '/login' with the actual URL of your login page

		return false;

	}

	const userObject = JSON.parse(storedUser);

	const accessToken = userObject.access_token;

	const role_idd = userObject.role_id;

	const user_idd = userObject.user_id;

	let setup_admin_permission = userObject.setup_admin_permission;

	let invite_user_permission = userObject.invite_user_permission;

	let suspend_user_permission = userObject.suspend_user_permission;

	var delete_user_permission = userObject.delete_user_permission;



	const [error, setError] = useState(null);

	// Create Users

	const [formData, setFormData] = useState({

		first_name: '',

		last_name: '',

		email: '',

		phone_number: '',

		buttonName: '',

		address_1: '',

		address_2: '',

		city: '',

		post_code: '',

		country: '',

		role_id: 0,

		setupAdmin: '0',

		inviteUser: '0',

		assignUnavailableMonth: '0',

		receiveMonthApproval: '0',

		suspendUser: '0',

		deleteUser: '0'

	});



	const [errors, setErrors] = useState({

		first_name: '',

		last_name: '',

		email: '',

		phone_number: '',

		address_1: '',

		address_2: '',

		city: '',

		post_code: '',

		country: '',

	});



	const [invalid, setInvalid] = useState(false);



	const handleInputChange = (e) => {

		const { name, value } = e.target;

		if (name == 'phone_number' && value.length <= 15) {

			setFormData({

				...formData,

				[name]: value,

			});

			setmobileNumber(value);



		} else {

			setFormData({

				...formData,

				[name]: value,

			});

		}

	};







	const handleCheckboxChange = (event) => {

		let value1 = "0";

		const { name, value } = event.target;



		let checkedValue = event.target.checked;



		if (checkedValue === true) {

			value1 = "1";

		} else {

			value1 = "0";

		}



		setFormData({

			...formData,

			[name]: value1,

		});



	};





	const handleSubmit = (e) => {

		e.preventDefault();

		// Get the name of the button clicked

		const buttonName = e.nativeEvent.submitter.name;



		const formsbutton = formData['buttonName'] = buttonName;



		//console.log(formData);

		// Validate email and password

		let valid = true;

		const newErrors = {

			first_name: '',

			last_name: '',

			email: '',

			phone_number: '',

			address_1: '',

			address_2: '',

			city: '',

			post_code: '',

			country: '',

		};



		if (!formData.email || !formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {

			newErrors.email = 'Please enter a valid email address';

			valid = false;

		}



		if (formData.email === "") {

			newErrors.email = 'Email field is required';

			valid = false;

		}

		if (formData.first_name === "") {

			newErrors.first_name = 'First Name field is required';

			valid = false;

		}

		if (formData.last_name === "") {

			//newErrors.last_name = 'Last Name field is required';

			//valid = false;

		}

		if (formData.phone_number === "") {

			newErrors.phone_number = 'Phone Number field is required';

			valid = false;

		} else {

			const ukMobileNumberRegex = /^(?:0|\+44)(?:\d\s?){9,10}$/;

			if (!ukMobileNumberRegex.test(formData.phone_number)) {

				newErrors.phone_number = 'Phone Number must be a valid number. (UK eg.+447975556677)';

				valid = false;

			}

		}

		if (formData.post_code === "" || formData.post_code === null) {

			//newErrors.post_code = 'Post Code field is required';

			//valid = false;

		} else {

			const ukPostcodeRegex = /^[a-zA-Z0-9\s]+$/;

			// const ukPostcodeRegex = /^[A-Z]\d{4}[A-Z]{2}$/;

			if (!ukPostcodeRegex.test(formData.post_code)) {

				newErrors.post_code = 'Please enter a valid post code. (e.g. D1234GH)';

				valid = false;

			}

		}



		if (formData.country === "" || formData.country === null) {

			//newErrors.country = 'Country field is required';

			//valid = false;

		}



		setErrors(newErrors);



		if (valid) {



			setLoading(true);

			const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/create';



			axios.post(apiBaseURL, formData, {

				headers: {

					Authorization: `Bearer ${accessToken}`

				}

			})

				.then(({ data }) => {

					if (data.status === "success") {

						setShowDefault(false);

						setLoading(false);

						window.location.reload();

					}

					if (data.status === "error") {

						setError('The email is already in use');

						setLoading(false);

					}

				}).catch(error => {

					setLoading(false);

				});



		}

	};

	// End Create Users



	const handleInputChangeSearch = (e) => {

		const { name, value } = e.target;
		setSearch(value);

	};



	var del_per = "";

	useEffect(() => {
		setLoading(true);
		const apiBaseURL1 = process.env.REACT_APP_API_BASE_URL + '/country_list';

		axios.get(apiBaseURL1, {})
			.then(({ data }) => {

				if (data.status === "success") {

					setCountry(data.data);

					const storedUsersss = localStorage.getItem('user');

					console.log("storedUsersss", storedUsersss);

					//localStorage.setItem('user', JSON.stringify(data.content));

					let user_id = storedUsersss.user_id

					const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/' + user_idd;
					const formData = new FormData();

					setLoading(true);

					axios.post(apiBaseURL, formData, {
						headers: {
							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
						}
					})
						.then(({ data }) => {
							if (data.status === "success") {

								console.log("YData", data.data.data);

								let udATA = {
									'status_code': 200,
									'access_token': accessToken,
									'token_type': 'Bearer',
									'user_name': data.data.data[0].name,
									'user_email': data.data.data[0].email,
									'user_id': data.data.data[0].id,
									'is_saving_commitment': data.data.data[0].is_saving_commitment,
									'role_id': data.data.data[0].role_id,
									'setup_admin_permission': data.data.data[0].setup_admin_permission,
									'invite_user_permission': data.data.data[0].invite_user_permission,
									'assign_month_permission': data.data.data[0].assign_month_permission,
									'approval_request_permission': data.data.data[0].approval_request_permission,
									'suspend_user_permission': data.data.data[0].suspend_user_permission,
									'delete_user_permission': data.data.data[0].delete_user_permission,
								}

								localStorage.setItem('user', JSON.stringify(udATA));
							}
							setLoading(false);
							setPageLoad(false);
						}).catch(error => {
							setLoading(false);
							setPageLoad(false);
						});
				}

			}).catch(error => {
			});
	}, []);





	if (loading && pageLoad) {

		// return <div>Loading...</div>;

	}

	console.log("delete_user_permission1", del_per);


	const btnWidth = {

		width: '125px'

	};



	return (

		<>

			{loading && <Preloader show={true} />}

			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">

				<div className="d-block mb-4 mb-md-0">

					<Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>

						<Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>

						<Breadcrumb.Item>Home</Breadcrumb.Item>

						<Breadcrumb.Item active>{role_idd !== 1 ? 'Deleted Users' : 'Deleted Users'}</Breadcrumb.Item>

					</Breadcrumb>

					<h4>{role_idd !== 1 ? 'Deleted Users' : 'Deleted Users'}</h4>

				</div>

				<div className="btn-toolbar mb-2 mb-md-0">

				</div>

			</div>



			<div className="table-settings mb-4">

				<Row className="justify-content-between align-items-center">

					<Col xs={8} md={6} lg={3} xl={4}>

						<InputGroup>

							<InputGroup.Text>

								<FontAwesomeIcon icon={faSearch} />

							</InputGroup.Text>

							<Form.Control type="text" placeholder="Search" name="searchKeyword" onChange={handleInputChangeSearch} />

						</InputGroup>

					</Col>

					<Col xs={4} md={2} xl={2} className="ps-md-0 text-end">

					</Col>

				</Row>

			</div>



			<DeletedUserTable data={search} />





			<React.Fragment>

				<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>

					<Modal.Header>

						<Modal.Title className="h6">Add User</Modal.Title>

						<Button variant="close" aria-label="Close" onClick={handleClose} />

					</Modal.Header>

					<Modal.Body>

						<Row>

							{error &&

								<Alert variant="danger">

									{error}

								</Alert>

							}

							<Col xs={12} xl={12}>

								<Card border="light" className="bg-white shadow-sm mb-4">

									<Card.Body>

										<h5 className="mb-4">Add User</h5>

										<Form onSubmit={handleSubmit}>

											<Row>

												<Col md={6} className="mb-3">

													<Form.Group id="firstName">

														<Form.Label>First Name<span class="error-message">*</span></Form.Label>

														<Form.Control type="text" name="first_name" placeholder="Enter first name" onChange={handleInputChange} />

													</Form.Group>

													<div className="error-message">{errors.first_name}</div>

												</Col>

												<Col md={6} className="mb-3">

													<Form.Group id="lastName">

														<Form.Label>Last Name</Form.Label>

														<Form.Control type="text" name="last_name" placeholder="Enter last name" onChange={handleInputChange} />

													</Form.Group>

													<div className="error-message">{errors.last_name}</div>

												</Col>

											</Row>

											<Row>

												<Col md={6} className="mb-3">

													<Form.Group id="emal">

														<Form.Label>Email<span class="error-message">*</span></Form.Label>

														<Form.Control type="email" name="email" placeholder="name@company.com" onChange={handleInputChange} />

													</Form.Group>

													<div className="error-message">{errors.email}</div>

												</Col>

												<Col md={6} className="mb-3">

													<Form.Group id="phone">

														<Form.Label>Phone<span class="error-message">*</span></Form.Label>

														<Form.Control type="text" name="phone_number" id="phone_number" placeholder="+44 9999 123456" onChange={handleInputChange} value={mobileNumber} />

													</Form.Group>

													<div className="error-message">{errors.phone_number}</div>

												</Col>

											</Row>

											<Row>

												<Col md={12} className="mb-3">

													<Form.Group id="address_1">

														<Form.Label>Address Line 1</Form.Label>

														<Form.Control type="text" name="address_1" placeholder="Address Line 1" value={formData.address_1} onChange={handleInputChange} />

													</Form.Group>

													<div className="error-message">{errors.address_1}</div>

												</Col>

											</Row>



											<Row>

												<Col md={12} className="mb-3">

													<Form.Group id="address_2">

														<Form.Label>Address Line 2</Form.Label>

														<Form.Control type="text" name="address_2" placeholder="Address Line 2" value={formData.address_2} onChange={handleInputChange} />

													</Form.Group>

													<div className="error-message">{errors.address_2}</div>

												</Col>

											</Row>



											<Row>

												<Col md={6} className="mb-3">

													<Form.Group id="city">

														<Form.Label>City</Form.Label>

														<Form.Control type="text" name="city" className={errors.post_code ? "is-invalid" : ""} placeholder="City" value={formData.city} onChange={handleInputChange} />

													</Form.Group>

													<div className="error-message">{errors.city}</div>

												</Col>

												<Col md={6} className="mb-3">

													<Form.Group id="post_code">

														<Form.Label>Post Code</Form.Label>

														<Form.Control type="text" name="post_code" placeholder="Post Code" className={errors.post_code ? "is-invalid" : ""} value={formData.post_code} onChange={handleInputChange} />

													</Form.Group>

													<div className="error-message">{errors.post_code}</div>

												</Col>

											</Row>



											<Row>

												<Col md={12} className="mb-3">

													<Form.Group id="country">

														<Form.Label>Country</Form.Label>

														<Form.Select defaultValue="" name="country" onChange={handleInputChange}>

															<option value="">Choose Country</option>

															{countries?.map((goal, index) => (
																(goal == 'UNITED KINGDOM') ?

																	<option key={goal} value={goal}>

																		{goal}

																	</option> : ""

															))}

														</Form.Select>

													</Form.Group>

													<div className="error-message">{errors.country}</div>

												</Col>

											</Row>

											{role_idd === 1 && (

												<Row>

													<Col md={12} className="mb-3">

														<Form.Group controlId="role_id">

															<Form.Label>Select Role:</Form.Label>

															<Form.Select name="role_id" onChange={handleInputChange} defaultValue="">

																<option value="0">User</option>

																{setup_admin_permission === "1" && (

																	<option value="1">Admin</option>

																)}

															</Form.Select>

														</Form.Group>

													</Col>

												</Row>

											)}





											{role_idd === 1 && formData.role_id === "1" && (

												<Row>

													<h5>Permission</h5>

													<Col md={12} className="mb-3">

														<Form.Group>

															<div className="d-flex justify-content-between align-items-center mb-1">

																<Form.Check type="checkbox">

																	<FormCheck.Input id="setupAdmin" onChange={handleCheckboxChange} name="setupAdmin" className="me-2" /><span>Setup Admin</span>

																</Form.Check>

															</div>

															<div className="error-message"></div>

														</Form.Group>

													</Col>

													<Col md={12} className="mb-3">

														<Form.Group>

															<div className="d-flex justify-content-between align-items-center mb-1">

																<Form.Check type="checkbox">

																	<FormCheck.Input id="inviteUser" onChange={handleCheckboxChange} name="inviteUser" className="me-2" /><span>Invite User</span>

																</Form.Check>

															</div>

															<div className="error-message"></div>

														</Form.Group>

													</Col>

													<Col md={12} className="mb-3">

														<Form.Group>

															<div className="d-flex justify-content-between align-items-center mb-1">

																<Form.Check type="checkbox">

																	<FormCheck.Input id="assignUnavailableMonth" onChange={handleCheckboxChange} name="assignUnavailableMonth" className="me-2" /><span>Assign Unavailable month to users</span>

																</Form.Check>

															</div>

															<div className="error-message"></div>

														</Form.Group>

													</Col>

													<Col md={12} className="mb-3">

														<Form.Group>

															<div className="d-flex justify-content-between align-items-center mb-1">

																<Form.Check type="checkbox">

																	<FormCheck.Input id="receiveMonthApproval" onChange={handleCheckboxChange} name="receiveMonthApproval" className="me-2" /><span>Receive Unavailable Month Approval Requests</span>

																</Form.Check>

															</div>

															<div className="error-message"></div>

														</Form.Group>

													</Col>

													<Col md={12} className="mb-3">

														<Form.Group>

															<div className="d-flex justify-content-between align-items-center mb-1">

																<Form.Check type="checkbox">

																	<FormCheck.Input id="suspendUser" onChange={handleCheckboxChange} name="suspendUser" className="me-2" /><span>Suspend User</span>

																</Form.Check>

															</div>

															<div className="error-message"></div>

														</Form.Group>

													</Col>

													<Col md={12} className="mb-3">

														<Form.Group>

															<div className="d-flex justify-content-between align-items-center mb-1">

																<Form.Check type="checkbox">

																	<FormCheck.Input id="deleteUser" onChange={handleCheckboxChange} name="deleteUser" className="me-2" /><span>Delete User</span>

																</Form.Check>

															</div>

															<div className="error-message"></div>

														</Form.Group>

													</Col>

												</Row>

											)}





											<div className="mt-3 center add-user-btn">

												{role_idd === 1 && (
													<>
														<Button variant="primary" data-button-name="save" type="submit" style={btnWidth}>
															Save
														</Button>
														{' '}
														{invite_user_permission === "1" && (
															<Button variant="primary" data-button-name="invite" name="invite" type="submit" style={btnWidth}>
																Save & Invite
															</Button>
														)}
													</>
												)}

												{role_idd === 0 && (
													<>
														<Button variant="primary" data-button-name="invite" name="invite" type="submit" style={btnWidth}>
															Save
														</Button>
														&nbsp;&nbsp;
														<Button variant="primary" data-button-name="invite" name="invite" type="submit" style={btnWidth}>
															Save & Invite
														</Button>
													</>
												)}

												&nbsp;&nbsp;

												<Button variant="primary" onClick={handleClose} style={btnWidth}>

													Cancel

												</Button>

												&nbsp;

											</div>



										</Form>

									</Card.Body>

								</Card>

							</Col>

						</Row>

					</Modal.Body>

				</Modal>

			</React.Fragment>



		</>

	);

};

